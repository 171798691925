.priority0 {
  background-image: url("../assets/icons/priority0.svg");
}
.priority1 {
  background-image: url("../assets/icons/priority1.svg");
}
.priority2 {
  background-image: url("../assets/icons/priority2.svg");
}
.priority3 {
  background-image: url("../assets/icons/priority3.svg");
}
.priority4 {
  background-image: url("../assets/icons/priority4.svg");
}
.priority5 {
  background-image: url("../assets/icons/priority5.svg");
}

.status12 {
  background-image: url("../assets/icons/asterisk.svg");
}
.status0 {
  background-image: url("../assets/icons/status0.svg");
}
.status1 {
  background-image: url("../assets/icons/status1.svg");
}
.status2 {
  background-image: url("../assets/icons/status2.svg");
}
.status3 {
  background-image: url("../assets/icons/status3.svg");
}
.status4 {
  background-image: url("../assets/icons/status4.svg");
}
.status5 {
  background-image: url("../assets/icons/status5.svg");
}
.status6 {
  background-image: url("../assets/icons/status6.svg");
}
.status7 {
  background-image: url("../assets/icons/status7.svg");
}
.status8 {
  background-image: url("../assets/icons/status8.svg");
}
.status9 {
  background-image: url("../assets/icons/status9.svg");
}
.status10 {
  background-image: url("../assets/icons/status10.svg");
}

.rating1 {
  background-image: url("../assets/icons/lvl1.svg");
}
.rating2 {
  background-image: url("../assets/icons/lvl2.svg");
}
.rating3 {
  background-image: url("../assets/icons/lvl3.svg");
}
.rating4 {
  background-image: url("../assets/icons/lvl4.svg");
}
.rating5 {
  background-image: url("../assets/icons/lvl5.svg");
}

.type0 {
  background-image: url("../assets/icons/type0.svg");
}
.type1 {
  background-image: url("../assets/icons/type1.svg");
}
.type2 {
  background-image: url("../assets/icons/type2.svg");
}
.type3 {
  background-image: url("../assets/icons/type3.svg");
}
.type4 {
  background-image: url("../assets/icons/type4.svg");
}
.type5 {
  background-image: url("../assets/icons/type5.svg");
}
.type6 {
  background-image: url("../assets/icons/type6.svg");
}
.type7 {
  background-image: url("../assets/icons/type7.svg");
}
