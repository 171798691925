@import "../../../styles/variables";

.checkbox {
  position: relative;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxInput {
  position: absolute;
  visibility: hidden;
}

.checkboxContainer {
  position: relative;
  display: flex;
  width: 16px;
  min-width: 16px;
  min-height: 16px;
  height: 16px;
}

.checkboxLoader {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.checkboxText {
  display: flex;
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  user-select: none;
}

.checkboxes {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #E6E6E6;
  border-radius: 4px;
  &:after {
    will-change: transform;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    width: 10px;
    height: 8px;
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.3941 8C3.23438 8 3.07452 7.93262 2.95244 7.79754L0.18342 4.73476C-0.060885 4.46461 -0.0611628 4.0263 0.182725 3.75568C0.426613 3.48522 0.822445 3.48491 1.06661 3.75507L3.3941 6.32938L8.93339 0.202597C9.17755 -0.0678653 9.57325 -0.0674038 9.81728 0.203212C10.0612 0.473828 10.0609 0.912137 9.81658 1.18229L3.83577 7.79754C3.71369 7.93262 3.55382 8 3.3941 8Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 10px 8px;
    background-position: center;
  }
}

.checked {
  .checkboxes {
    background-color: $blue;
    &:after {
      opacity: 1;
    }
  }
}

.disabled {
  .checkboxes {
    background-color: #E6E6E6;
  }
  &.checkboxLabel {
    pointer-events: none;
  }
}