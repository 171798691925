@import "../../../styles/variables";
@import "../../../styles/media";

.trackTime {
  background: $white;
  min-height: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid rgba(0, 0, 0, 0.2);
  pointer-events: none;
  user-select: none;
  @include mobile {
    display: none;
  }
}

.workedOut {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 400;
  font-size: 10px;
  color: $black100;
}

.trackHours {
  flex: 1;
  position: relative;

  &:after {
    content: '';
    height: 3px;
    width: .5px;
    position: absolute;
    top: 0;
    left: 25%;
    transform: translateX(-25%);
    background: rgba(0, 0, 0, 0.2);
  }

  &:before {
    content: '';
    height: 3px;
    width: .5px;
    position: absolute;
    top: 0;
    right: 25%;
    transform: translateX(-25%);
    background: rgba(0, 0, 0, 0.2);
  }
}

.hours {
  position: absolute;
  bottom: 1px;
  left: 3px;
  font-weight: 400;
  font-size: 8px;
  color: rgba(0, 0, 0, 0.4);
  z-index: 3;
}

.half {
  height: 6px;
  width: .5px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.2);
}

.borderRight {
  height: 100%;
  width: 0.5px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: .5px solid rgba(0, 0, 0, 0.2);
}

.track {
  display: block;
  height: calc(100% + 1px);
  pointer-events: all;
  z-index: -1;
  position: absolute;

  &:before {
    content: '';
    height: 100%;
    width: .5px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;
  }

  &:hover {
    .toolTip {
      opacity: 1;
    }
  }
}

.toolTip {
  pointer-events: none;
  opacity: 0;
  transition: .2s ease-in-out;
  position: absolute;
  top: calc(100%);
  left: 50%;
  transform: translateX(-50%);
  max-width: 252px;
  width: max-content;
  padding-top: 9px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

  &.right {
    left: auto;
    right: -14px;
    transform: none;

    .toolTipContent {
      &:before {
        left: auto;
        right: 5px;
        transform: none;
      }
    }
  }

  &.left {
    left: -14px;
    transform: none;

    .toolTipContent {
      &:before {
        left: 5px;
        transform: none;
      }
    }
  }
}

.toolTipContent {
  display: block;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -9px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 12px solid $white;
    transform: translateX(-50%);
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
    z-index: -1;
  }
}