@import "../../../styles/variables";

.wrapper {
  width: 600px;
  p {
    margin: 24px 0;
    font-weight: 400;
    line-height: 22px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.selectWrapper {
  margin: 24px 0;
}

.select {
}

.buttons {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 8px;
  }
}

.buttonsMargin {
  margin-top: 40px;
}