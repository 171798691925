.tasksTable {
  div[data-column] {
    flex-shrink: 0;
    text-align: center;
    justify-content: center;
  }
  div[data-column="title"] {
    min-width: 240px;
    flex-shrink: initial;
    flex-grow: 1;
    text-align: left;
    justify-content: flex-start;
  }
  .tableHead {
    div[data-column="title"] {
      min-width: 291px;
    }
  }
  div[data-column="type"] {
    width: 39px;
    @include mobile {
      display: none;
    }
  }
  div[data-column="priority"] {
    width: 82px;
    @include mobile {
      display: none;
    }
  }
  div[data-column="difficulty"] {
    width: 72px;
    @include mobile {
      display: none;
    }
  }
  div[data-column="status"] {
    width: 58px;
    @include mobile {
      display: none;
    }
  }
  div[data-column="wdate"] {
    width: 110px;
    line-height: 20px;
    @include mobile {
      display: none;
    }
  }
  div[data-column="edate"] {
    width: 85px;
    @include mobile {
      display: none;
    }
  }
  div[data-column="created_at"] {
    width: 85px;
    @include mobile {
      display: none;
    }
  }
  div[data-column="updated_at"] {
    width: 85px;
    @include mobile {
      display: none;
    }
  }
}

.projectsTable {
  div[data-column] {
    flex-shrink: 0;
    text-align: center;
    justify-content: center;
  }
  div[data-column="title"] {
    flex-shrink: initial;
    flex-grow: 1;
    text-align: left;
    justify-content: flex-start;
  }
  div[data-column="client"] {
    width: 60px;
  }
  div[data-column="type"] {
    width: 120px;
  }
  div[data-column="cdate"] {
    width: 100px;
  }
  div[data-column="users"] {
    width: 250px;
    justify-content: flex-end;
    text-align: right;
  }
}

.usersTable {
  div[data-column] {
    flex-shrink: 0;
    text-align: center;
    justify-content: center;
  }
  div[data-column="name"] {
    display: flex;
    align-items: center;
    flex-shrink: initial;
    flex-grow: 1;
    text-align: left;
    justify-content: flex-start;
  }
  div[data-column="roleId"] {
    width: 200px;
  }
  div[data-column="groups"] {
    width: 450px;
    justify-content: flex-end;
    text-align: right;
  }
  div[data-column="controls"] {
    width: 24px;
  }
}

.usersProjectTable {
  & > div:nth-child(2) {
      min-height: calc(100vh - 210px);
  }
  div[data-column] {
    flex-shrink: 0;
  }
  div[data-column="name"] {
    display: flex;
    align-items: center;
    flex-shrink: initial;
    flex-grow: 1;
  }
  div[data-column="email"] {
    width: 300px;
  }
  div[data-column="roleId"] {
    width: 125px;
  }
  div[data-column="controls"] {
    width: 24px;
  }
}