.wrapper {
  width: 512px;
  border-radius: 16px !important;
  & > *:not(.title, .button) {
    margin-top: 16px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.button {
  float: right;
  margin-top: 32px;
}

.select {
  height: 42px !important;
}