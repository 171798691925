.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  @include mobile {
    max-width: 100%;
  }
}

.content {
  width: 100%;
  padding: 40px;
  min-height: calc(100vh - 80px);
  h2 {
    font-weight: 700;
    font-size: 24px;
    span {
      font-size: 16px;
      font-weight: 400;
      color: $gray300;
      margin-left: 40px;
    }
  }
  @include mobile {
    padding: 30px 0;
    min-height: calc(100vh - 90px);
    h2 {
      font-size: 22px;
    }
  }
}

.contentWithTable {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentHeader {
  display: flex;
  .create {
    color: $blue;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 24px;
    & > * {
      cursor: pointer;
    }
  }
  @include mobile {
    & > div:not(.create) {
      display: none;
    }
    .create {
      height: 32px;
      font-size: 12px;
      font-weight: 600;
      margin-left: 14px;
    }
  }
}

.contentTitle {
  margin-left: 40px;
  font-weight: 400;
  transform: translateY(4px);
  line-height: 24px;
  a {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  a:first-child {
    padding-left: 0;
  }
  span {
    cursor: pointer;
    white-space: pre;
    &:hover {
      path {
        stroke: $blue;
      }
    }
    svg {
      width: 14px;
      height: 14px;
      transform: translateY(2px);
      path {
        transition: all 0.3s ease;
        stroke: $gray300;
      }
    }
  }
}

.contentSubtitle {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $borderColor;
  margin-top: 40px;
  padding-bottom: 13px;
  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  span {
    font-weight: 500;
  }
}
