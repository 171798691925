@import "../../../styles/variables";

.calendarWrapper {
  width: 440px;
  height: 100%;
  position: relative;
}

.daysWeek {
  height: 35px;
  display: flex;
}

.dayWeek {
  width: calc(100% / 7);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: $gray300;
}

.calendar {
  border-bottom: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-right: 54px;
}

.day {
  width: calc(100% / 7);
  height: 55px;
  border-top: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
}

.tasksInDay {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $gray400;
  color: $white;
  border-radius: 50%;
  font-weight: 400;
  font-size: 9px;
  position: absolute;
  top: 4px;
  left: 8px;
}

.dayDate, .weekNumber {
  position: absolute;
  top: 5px;
  right: 5px;
}

.weekNumber {
  color: $blue;
}

.dayTime {
  font-size: 14px;
  position: relative;
  top: 10px;
}

.dayToday {
  background-color: $blue;
  color: $white;

  .dayTime {
    font-weight: 400;
    color: $white;
  }
}

.dayBefore {
  background-color: $borderColor;
  .dayDate {
    font-weight: 300;
  }
  .dayTime {
    font-weight: 400;
  }
}

.cursorPointer {
  cursor: pointer;
}

.weekTotalList {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 54px;
}

.weekTotal {
  height: 55px;
  width: 100%;
  font-weight: 500;
  font-size: 11px;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  border-top: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  position: relative;

  .dayTime {
    color: $gray300;

    &.currentDate {
      & > p {
        border-bottom: thin solid $gray300;
      }
    }

    & > p {
      color: $black;
    }
  }
}

.timeLeft {
  color: $gray300;
}

.border {
  width: 75%;
  border-top: 1px solid $black;
  padding-top: 2px;
  margin-top: 2px;
}

.currentDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 11px;

  > p {
    border-bottom: thin solid $white;
  }
}

.active {
  pointer-events: none;
  cursor: pointer;
}
