@import "../../styles/variables";
@import "../../styles/media";

.layout {
  width: 100%;
  padding: 80px 0 0 80px;
  @include mobile {
    min-height: 100vh;
    height: 100%;
    position: relative;
    padding: 60px 16px 0 16px;
  }
}

.flex {
  display: flex;
}

.loader {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  & > div > div {
    background-color: $red;
  }
}

.layoutTrackTimer {
  position: fixed;
  left: 80px;
  right: 0;
  top: 80px;
  z-index: 99;
}