@import "../../styles/variables";

.avatar {
  :root {
    --fillPercent: 0;
  }

  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: $white;
  border-radius: 50%;
  position: relative;
  padding: 2px;

  img {
    width: inherit;
    height: inherit;
    position: absolute;
    overflow: hidden;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 50%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    top: -3px;
    left: -3px;
    z-index: -1;
    opacity: 0;
  }
}

.userSmall {
  width: 24px;
  height: 24px;
}

.userBig {
  width: 36px;
  height: 36px;
}

.userProfile {
  width: 140px;
  height: 140px;

  &:before {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    top: -4px;
    left: -4px;
  }
}

.projectSmall {
  width: 56px;
  height: 56px;
  min-width: 56px;
  border-radius: 4px;
}

.attention {
  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $red;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
  }
}

.lvl1 {
  &:before {
    opacity: 1;
    background-image: conic-gradient($green 0 calc(var(--fillPercent) * 360deg), $green100 calc(var(--fillPercent) * 360deg) 1turn);
  }
}

.lvl2 {
  &:before {
    opacity: 1;
    background-image: conic-gradient($orange 0 calc(var(--fillPercent) * 360deg), $orange100 calc(var(--fillPercent) * 360deg) 1turn);
  }
}

.lvl3 {
  &:before {
    opacity: 1;
    background-image: conic-gradient($red200 0 calc(var(--fillPercent) * 360deg), $red300 calc(var(--fillPercent) * 360deg) 1turn);
  }
}

.lvl4 {
  &:before {
    opacity: 1;
    background-image: conic-gradient($blue 0 calc(var(--fillPercent) * 360deg), $blue400 calc(var(--fillPercent) * 360deg) 1turn);
  }
}

.lvl5 {
  &:before {
    opacity: 1;
    background-image: conic-gradient($purple 0 calc(var(--fillPercent) * 360deg), #f5e3fd calc(var(--fillPercent) * 360deg) 1turn);
  }
}