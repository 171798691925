@import "../../../styles/variables";
@import "../../../styles/media";

.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 25px;
  background-color: $marine;
  color: $white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  & > div {
    flex-shrink: 0;
  }
  @include mobile {
    height: 60px;
    padding: 0 16px;
    background-color: $white;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  }
}

.burger {
  display: none;
  align-items: center;
  @include mobile {
    display: none;
  }
}

.group {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  .attention {
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
    }
  }
  .chevron {
    display: flex;
    align-items: center;
    svg {
      margin-left: 10px;
      transition: all 0.3s ease;
      path {
        fill: $gray300;
      }
    }
  }
  @include mobile {
    //position: absolute;
    //top: 50%;
    //bottom: 50%;
    //left: 50%;
    //margin-left: -16px;
  }
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.groupDropdown {
  width: 364px;
  max-height: fit-content;
  opacity: 0;
  visibility: hidden;
  background-color: $white;
  filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.25));
  display: flex;
  border-radius: 0 0 4px 0;
  position: absolute;
  top: 80px;
  left: 0;
  cursor: default;
  transition: all .3s ease;
  @include mobile {
    width: 100%;
    top: 60px;
    filter: none;
    border-top: 1px solid $borderColor;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  }
}

.groupDrop {
  .chevron {
    svg {
      transform: rotate(-180deg);
    }
  }
  .groupDropdown {
    max-height: max-content;
    opacity: 1;
    visibility: visible;
  }
}

.groups {
  width: 80px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid $borderColor;
  & > * {
    width: 32px;
    height: 32px;
    cursor: pointer;
    position: relative;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
  .groupListLoader {
    position: initial;
    margin: initial;
  }
  .active {
    &::after {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      border: 2px solid $blue;
      border-radius: 50%;
      position: absolute;
      top: -4px;
      left: -4px;
    }
  }
  .allActive {
    @extend .active;
    &::after {
      border-radius: 12px;
    }
  }
  svg {
    width: 32px;
    height: 32px;
  }
}

.info {
  width: 285px;
  padding: 16px 24px;
  .buttons {
    margin-top: 24px;
    button {
      &:not(:first-of-type) {
        margin-top: 8px;
      }
    }
  }
  .loader {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.infoHeader {
  display: flex;
  flex-shrink: 0;
}

.infoTitle {
  color: $black;
  font-weight: 500;
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.infoSubTitle {
  color: $gray300;
  font-size: 12px;
  font-weight: 400;
}

.nav {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  text-align: center;
  margin: 0 25px;
  a {
    padding: 0 4px 2px;
    font-weight: 400;
    opacity: 0.5;
    position: relative;
    transition: all .3s ease;
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: $red;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: all .3s ease;
    }
    &:hover {
      opacity: 1;
    }
    &:not(:first-child) {
      margin-left: 32px;
    }
    &.active {
      opacity: 1;
      &::after {
        width: 100%;
      }
    }
  }
  svg {
    display: none;
  }
  @include mobile {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;
    box-shadow: inset 0 0.33px 0 rgba(0, 0, 0, 0.25);
    padding: 0 40px;
    a {
      display: none;
      font-size: 12px;
      font-weight: 500;
      color: $gray300;
      margin: 0 !important;
      padding: 0;
      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        order: 3;
      }
      &::after {
        display: none;
      }
      &.active {
        color: $black;
      }
    }
    svg {
      display: block;
      margin: 0 auto 3px;
    }
  }
}

.currentTask {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
  opacity: 0.5;
  transition: all 0.3s ease;
  &:hover {
    opacity: 1;
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: $white;
    }
  }
  span {
    max-width: 200px;
    font-weight: 500;
    margin-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @include mobile {
    opacity: 1;
    margin-right: 0;
    svg {
      path {
        fill: $gray500;
      }
    }
    span {
      display: none;
    }
  }
}

.notifications {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0 15px;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    transition: all 0.3s ease;
    path {
      pointer-events: none;
    }
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
  @include mobile {
    margin: 0 0 0 15px;
    opacity: 1;
    svg {
      path {
        fill: #B8B8B8;
      }
    }
  }
  .counter {
    display: none;
    min-width: 14px;
    height: 14px;
    line-height: 14px;
    border-radius: 7px;
    background-color: $red;
    padding: 0 4px;
    position: absolute;
    top: -5px;
    left: 9px;
    font-size: 10px;

    &.visible {
      display: block;
    }
  }
}

.profile {
  height: 80px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 9;
  position: relative;
  &:hover {
    background-color: hsla(0,0%,100%,.1);
  }
  svg {
    fill: $white;
    transition: all 0.3s ease;
  }
  a {
    width: 100%;
    text-align: center;
    opacity: 1;
  }
  @include mobile {
    display: none;
  }
}

.profileName {
  margin: 0 8px;
}

.drop {
  color: $marine;
  svg {
    fill: $marine !important;
    transform: rotate(180deg);
  }
  .profileDrop {
    opacity: 1;
    pointer-events: all;
    padding: 100px 0 20px;
  }
}

.profileDrop {
  display: flex;
  width: 100%;
  background-color: $white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-bottom-left-radius: 4px;
  box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  opacity: 0;
  padding: 80px 0 20px;
  transition: opacity .4s ease-in-out, padding .4s ease-in-out;
  div {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    > a {
      width: 100%;
      height: 100%;
      transition: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
