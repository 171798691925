@import "../../../../styles/variables";

.content {
  width: 600px;
  position: relative;
}

.contentHeader {
  flex-direction: column;
  h2 {
    font-size: 32px;
    font-weight: 500;
  }
  p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
    &:first-of-type {
      margin-top: 24px;
    }
  }
}

.form {
  margin-top: 32px;
}

.colorAvatar {
  margin-top: 24px;
}

.bigLabel {
  label {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $black;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  button {
    margin-left: 8px;
  }
}