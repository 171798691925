.color0 {
  background-color: #7EA4E7;
}

.color1 {
  background-color: #7BB7C8;
}

.color2 {
  background-color: #77CAA9;
}

.color3 {
  background-color: #B7CA79;
}

.color4 {
  background-color: #D7CA61;
}

.color5 {
  background-color: #F6C948;
}

.color6 {
  background-color: #E8A66E;
}

.color7 {
  background-color: #DA8394;
}

.color8 {
  background-color: #AC94BE;
}

.color9 {
  background-color: #C5A647;
}

.color10 {
  background-color: #E8A66E;
}

.colorSuccess {
  color: #77caa9;
}

.colorWarning {
  color: #e29964;
}

.colorError {
  color: #d56379;
}