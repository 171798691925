@import "../../styles/variables";

.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 106;
}

.whiteFill {
  background-color: $white;
  .children {
    position: initial;
  }
}

.filter {
  backdrop-filter: blur(3px);
}

.children {
  background-color: $white;
  border-radius: 4px;
  padding: 48px;
  position: relative;
}

.close {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  &:hover {
    path {
      fill: $blue;
    }
  }
  svg {
    transform: rotate(45deg);
    path {
      fill: $black;
      transition: all 0.3s ease;
    }
  }
}