@import "../../../styles/variables";

.wrapper {
  width: 100%;
  min-height: 100vh;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 500px;
}

.title {
  font-size: 30px;
  font-weight: 600;
}

.subTitle {
  font-size: 20px;
  font-weight: 400;
  margin-top: 12px;
}

.form {
  .code {
    margin: 48px 0;
    input {
      width: 56px !important;
      height: 56px !important;
      border-radius: 8px;
      border-color: #D0D0D0 !important;
      margin: 0 !important;
      font-size: 24px;
      font-weight: 700;
      &:not(:last-child) {
        margin-right: 8px !important;
      }
    }
  }
  .error {
    margin: -25px 0 25px;
    color: $red;
  }
  .repeatSend, .repeatSendTimer {
    font-size: 20px;
    font-weight: 400;
    line-height: 23.7px;
    &.repeatSendTimer {
      color: $gray300;
    }
    p {
      color: $linkColor;
      text-decoration: underline;
      text-underline-offset: 4px;
      cursor: pointer;
    }
  }
}

.close {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  &:hover {
    path {
      fill: $blue;
    }
  }
  svg {
    transform: rotate(45deg);
    path {
      fill: $black;
      transition: all 0.3s ease;
    }
  }
}