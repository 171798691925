@import "../../../styles/variables";

.inputContainer {
  position: relative;
  textarea {
    border: 1px solid $gray200 !important;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px !important;
    width: 100%;
    background-color: transparent;
    height: 132px;
  }
}

.error {
  label {
    color: $red;
  }
  input {
    border: 1px solid $red !important;
  }
}

.inputTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputLabel {
  display: block;
  margin-bottom: 7px;
  font-size: 14px;
  color: $gray300;
}

.errorMessage {
  margin-bottom: 7px;
  color: $red;
  font-size: 12px;
}

.inputBig {
  height: 56px;
  & + .inputVisibilityPassword {
    width: 54px;
    height: 54px;
  }
}

.inputSmall {
  height: 32px;
  font-size: 14px;
  padding: 0 28px 0 10px;
}

.onlyBorderBottom {
  border: none;
  padding: 0;
  border-bottom: 2px solid $gray100;
  border-radius: 0;
  transition: all 0.3s ease;
  &:focus {
    border: none;
    border-bottom: 2px solid $blue;
  }
}

.withoutBorder {
  border: none;
  padding: 0;
  height: auto;
  &:focus {
    border: none;
  }
}

.inputTypePassword {
  position: relative;
  input {
    padding-right: 54px;
  }
}

.inputVisibilityPassword {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FAFAFA;
  cursor: pointer;
  border-radius: 6px;
  position: absolute;
  top: 1px;
  right: 1px;
}

input[type="search"] {
  padding-right: 30px;
}

.search {
  width: 32px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  path {
    transition: all 0.3s ease;
  }
  &:hover {
    path {
      fill: $blue;
    }
  }
}

.loader {
  width: 20px;
  height: 20px;
  position: absolute !important;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  &.withoutPadding {
    right: 0;
  }
}

.disabled {
  input {
    cursor: default;
  }
}
