.frontendError {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  p {
    font-weight: 500;
    margin: 32px 0;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    text-align: center;
    line-height: 22px;
  }
}
