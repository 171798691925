@import "../../styles/variables";

.scroll {
  width: 2px;
  height: 100%;
  position: absolute;
}

.scrollBar {
  width: inherit;
  background-color: $gray100;
  position: relative;
}

.scrollSlider {
  width: inherit;
  background-color: $gray300;
  position: absolute;
  top: 0;
  left: 0;
}
.scrollSliderLoading {
  width: inherit;
  background-color: $red300;
  position: absolute;
  top: 0;
  left: 0;
  transition: all ease-out .5s;
}

.hiddenScroll {
  height: 0 !important;
}
