@import "../../../styles/variables";

.wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    .icon {
      path {
        fill: $blue;
      }
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  svg {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    flex-shrink: 0;
    transition: all 0.3s ease;
    path {
      fill: $gray300;
    }
  }
}

.datePickerWrapper {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -16px;
}

.datePickerInput {
  height: 32px;
  font-size: 14px;
  padding-right: 25px;
  border: none !important;
  outline: none !important;
  text-align: end;
  position: relative;
  cursor: default;
  transition: all 0.3s ease;
  &:hover {
    path {
      fill: $blue;
    }
  }
  svg {
    width: 16px;
    height: 16px;
    margin-left: 10px;
    flex-shrink: 0;
    path {
      fill: $gray300;
    }
  }
  &.disabled {
    cursor: default;
    padding-right: 0;
  }
}

.loader {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}