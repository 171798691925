@import "../../../styles/variables";

.wrapper {
  width: calc(100vw - 80px);
  height: calc(100vh - 100px);
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2.7182817459106445px);
  position: fixed;
  top: 100px;
  right: 0;
  z-index: 88;
  transition: all .5s ease;
}

.open {
  .container {
    animation: .6s openWrapper;
  }
}

@keyframes openWrapper {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.container {
  width: 600px;
  height: 100%;
  background: $white;
  box-shadow: 4px 0 16px 0 rgba(227, 227, 227, 0.50);
  margin-left: auto;
  padding: 25px 30px;
  position: relative;
}

.contentHeader {
  display: flex;
  justify-content: space-between;
  h2 {
    padding-right: 5px;
  }
  h5 {
    color: $gray300;
  }
  div {
    flex-grow: 1;
    text-align: right;
    span {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 700;
      color: #959595;
      opacity: 0.7;
      cursor: pointer;
      transition: all 0.3s ease;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.list {
  max-height: calc(100% - 28px);
  margin-top: 25px;
  overflow-y: scroll;
  position: relative;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 25px 0;
  &:not(:last-child) {
    border-bottom: 1px solid $borderColor;
  }
  &.info {
    .icon {
      svg {
        path {
          fill: $blue;
        }
      }
    }
    .title {
      color: $blue;
    }
    .timeBar {
      background-color: $blue;
    }
  }
  &.warden, &.system {
    .title {
      color: $orange;
    }
    .timeBar {
      background-color: $orange;
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  flex-grow: 1;
  white-space: pre-wrap;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $black;
  margin-top: 8px;
  padding-right: 15px;
  a {
    color: $blue;
    text-decoration: underline;
  }
}

.viewed {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $red200;
  flex-shrink: 0;
  position: absolute;
  right: 0;
}

.scroll {
  height: calc(100% - 78px) !important;
  position: absolute;
  top: 78px;
  right: 12.5px;
}