@mixin mediumDesktop() {
  @media (max-width: 1599px) {
    @content;
  }
}

@mixin smallDesktop() {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin mobile() {
  @media (max-width: 768px) {
    @content;
  }
}
