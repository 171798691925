body {
  font-family: $rubik;
  font-weight: 300;
  color: $black;
  background-color: $white;
  line-height: initial;
  overflow-x: hidden;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}

input {
  width: 100%;
  height: 40px;
  border: 1px solid #BCBCBC;
  border-radius: 4px;
  background-color: transparent;
  padding: 0 15px;
  outline: none;
  &:focus {
    border: 1px solid $blue;
  }
  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
  &[type="checkbox"] {
    width: initial;
    height: initial;
  }
}

button {
  width: max-content;
  height: 40px;
  background-color: transparent;
  border: 1px solid $gray100;
  border-radius: 4px;
  padding: 0 40px;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.916667px;
  text-transform: uppercase;
  color: $black;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid $blue;
    background-color: $blue;
    color: $white;
  }
}

.disabled {
  pointer-events: none !important;
  cursor: default !important;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.popoverDatePicker {
  .react-datepicker {
    border: none;
    box-shadow: $boxShadow;
    border-radius: 4px;
    font-family: $rubik;
    display: flex;
    &__triangle {
      display: none;
    }
    &__header {
      background-color: initial;
      border-bottom: none;
      padding-bottom: 0;
      &--time {
        display: none;
      }
    }
    &__time-container {
      padding: 0.4rem 0;
      border-left: 1px solid $borderColor;
    }
    &__current-month {
      font-weight: 400;
      font-size: 14px;
      padding: 5px 0;
    }
    &__time-list {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__time-list-item {
      height: initial !important;
      padding: 10px !important;
      &--selected {
        background-color: $blue !important;
      }
    }
    &__day-name {
      &:not(:nth-last-child(n+3)) {
        color: $red;
      }
    }
    &__navigation {
      top: 9px;
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      &-icon {
        &::before {
          width: 8px;
          height: 8px;
          border-width: 2px 2px 0 0;
        }
      }
    }
    &__day--keyboard-selected {
      background-color: transparent !important;
    }
    &__day--today {
      background-color: rgba($red, 0.1) !important;
      border-radius: 4px;
      font-weight: initial;
      outline: none;
    }
    &__day--selected {
      background-color: $blue !important;
      border-radius: 4px;
      border: none;
      outline: none;
      &.react-datepicker__day--weekend {
        color: $white;
      }
    }
    &__day--outside-month {
      opacity: 0.3;
    }
    &__day--weekend {
      color: $red;
    }
    &__day--disabled {
      color: $gray200 !important;
    }
  }
}

.image-gallery-swipe {
  outline: none;
  img {
    height: auto;
  }
}

.withoutScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  color: $black;
  opacity: 0.8;
}

.chartLegendItem {
  margin-right: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;

  > span {
    position: relative;
    width: 31px;
    margin-right: 12px;
    display: inline-block;
    height: 2px;

    > p {
      position: absolute;
      width: 7px;
      height: 7px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1.5px solid #FBFAF9;
      border-radius: 100%;
    }
  }
  > p {
    color: #959595;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.globalLoader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  & > div > div {
    background-color: $red;
  }
}

.pointer {
  cursor: pointer;
}

.colorRed {
  color: $red;
}

.image-gallery-image {
  height: max-content;
}

.opacity {
  opacity: 0.5;
}