@import "../../styles/variables";
@import "../../styles/media";

.authContainer {
  display: flex;
  height: 100vh;
}

.firstColumn {
  width: calc(100% - 640px);
  display: flex;
  flex-direction: column;
  @include mobile {
    width: 100%;
  }
}

.routing {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 36px 61px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.08333px;
  text-transform: uppercase;
  @include mobile {
    padding: 30px 15px;
  }
  a {
    padding: 0 4px 2px;
    opacity: 0.5;
    position: relative;
    transition: all .3s ease;
    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background-color: $red;
      position: absolute;
      bottom: -2px;
      left: 0;
      transition: all .3s ease;
    }
    &:hover {
      opacity: 1;
    }
    &:not(:first-child) {
      margin-left: 20px;
    }
    &.active {
      opacity: 1;
      &::after {
        width: 100%;
      }
    }
  }
}

.secondColumn {
  width: 640px;
  background-image: url("../../assets/images/auth-back.png");
  @include mobile {
    display: none;
  }
}

.authContent {
  width: 100%;
  max-width: 480px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 93.5px;
  @include mobile {
    padding: 0 15px;
  }
  h2 {
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    margin-bottom: 80px;
  }
  button {
    width: 100%;
    margin-top: 40px;
  }
}

.inputBlock {
  &:not(:first-child) {
    margin-top: 20px;
  }
  label {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $black;
  }
}

.serverError {
  color: $red;
  margin: 25px 0 -10px;
}

.resetPasswordLink {
  width: max-content;
  font-size: 12px;
  margin-top: 15px;
  padding: 0;
}