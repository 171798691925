@import "../../styles/variables";
@import "../../styles/media";

.param {
  width: fit-content;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $borderColor;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.label {
  width: 110px;
  color: $gray300;
  margin-right: 15px;
}

.withoutBorder {
  border-bottom: none;
}