@import "../../../styles/variables";

.content {
  padding: 40px 40px 0 !important;
}

.contentSubtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtitle {
  display: flex;
  align-items: center;
}

.pencil {
  margin-left: auto;
  margin-right: 15px;
  width: 16px;
  height: 16px;
  svg {
    width: inherit;
    height: inherit;
  }
}

.selectSprints {
  width: 250px;
  svg {
    width: initial !important;
    height: initial !important;
  }
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 0;
  border-bottom: 1px solid $borderColor;
  & > div:not(:first-child) {
    margin-left: 20px;
  }
}

.title {
  width: 170px;
}

.dates {
  display: flex;
  & > label:first-of-type {
    & > div:first-of-type {
      width: 165px;
    }
  }
  & > label:last-of-type {
    & > div:first-of-type {
      width: 120px;
    }
  }
}

.date {
  height: 32px;
  &:not(:first-child) {
    margin-left: 40px;
  }
}

.datePicker {
  height: 32px;
}

.statuses {
  height: 32px;
}

.statusesSelect {
  width: 160px;
}

.container {
  height: calc(100vh - 337px);
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
  & > div:not(:first-child) {
    margin-left: 24px;
  }
}

.backlog {
  width: 50%;
  position: relative;
  margin: -12px 0;
  .additional {
    padding-bottom: 10px;
    grid-column: span 2;
    & > div {
      background-color: transparent;
      & > div {
        background-color: $red;
      }
    }
  }
  .scroll {
    height: calc(100% - 24px);
    position: absolute;
    top: 12px;
    left: -20px;
  }
}

.allTasksContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-auto-rows: max-content;
}

.sprintTasks {
  width: 25%;
  background-color: #F4F6F8;
  border-radius: 4px;
  padding: 12px 24px;
  flex-shrink: 0;
  position: relative;
  .scroll {
    height: calc(100% - 48px);
    position: absolute;
    top: 24px;
    right: 12px;
  }
}

.tasksSprintScrollable {
  display: flex;
  flex-direction: column;
}

.cardWrapper {
  width: 100%;
}

.card {
  margin: 12px 0;
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
}

.times {
  width: 25%;
  height: max-content;
  background: #F5F5F5;
  border-radius: 4px;
  padding: 24px;
  flex-shrink: 0;
  position: relative;
}

.timeLine {
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 12px;
  }
  a {
    display: flex;
    align-items: center;
  }
  .name {
    color: $blue100;
    margin: 0 12px;
    font-weight: 400;
  }
  .difficulty {
    flex-grow: 1;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -20px 0 0 -12.5px;
}
