.w-md-editor {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #3D4044 !important;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  background-color: white;
  & > &-toolbar {
    height: 38px !important;
    border-radius: 4px 4px 0 0 !important;
    flex-shrink: 0;
    background-color: #f5f5f5 !important;
    svg {
      path {
        fill: #757575;
      }
    }
  }
  & > &-content {
    flex-grow: 1;
  }
  textarea {
    min-height: 261px;
  }
  &-input {
    min-height: 261px;
    height: 100%;
  }
  .content {
    padding: 0;
    min-height: initial;
  }
  &-text {
    code, textarea {
      font-family: 'JetBrains Mono', monospace !important;
    }
  }
}

.wmde-markdown {
  color: #3D4044 !important;
  pre {
    code {
      span {
        white-space: pre-wrap;
        word-break: break-word;
      }
    }
  }
}

.markdown {
  & > *:not(ol, ul, li) {
    white-space: break-spaces;
    overflow-wrap: anywhere;
  }
  ul {
    list-style: initial;
  }
  input[type="checkbox"] {
    width: 14px;
    height: 14px;
    transform: translateY(2px);
    margin-left: -16px;
    cursor: pointer;
  }
  pre {
    code {
      white-space: pre-wrap !important;
    }
  }
}