@import "../../styles/variables";

.content {
  width: 500px;
  position: relative;
}

.contentHeader {
  flex-direction: column;
  h2 {
    font-size: 32px;
    font-weight: 500;
  }
  p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin-top: 12px;
    &:first-of-type {
      margin-top: 24px;
    }
  }
}

.form {
  margin-top: 32px;
}

.colorAvatar {
  margin-top: 24px;
}

.label {
  display: block;
  margin-bottom: 7px;
  font-size: 14px;
  color: $gray300;
}

.bigLabel {
  label {
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $black;
  }
}

.chooseBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 400;
    line-height: 20px;
  }
}

.colors {
  width: 327px;
  height: 96px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.color {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-top: 8px;
  position: relative;
  cursor: pointer;
  &.active {
    &::before {
      border-color: inherit;
    }
  }
  &:hover {
    &::before {
      border-color: inherit;
      opacity: 0.5;
    }
  }
  &::before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid transparent;
    position: absolute;
    top: -4px;
    left: -4px;
  }
  &:not(:nth-child(5n+5)) {
    margin-right: 37px;
  }
}

.avatar {
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $blue;
  border-radius: 50%;
  flex-shrink: 0;
  cursor: pointer;
}

.buttons {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  button {
    width: 100%;
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}

.buttonAdd {
  width: 100%;
}