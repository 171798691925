@import "../../styles/variables";

.buttonBig {
  height: 56px;
  font-size: 13px;
  letter-spacing: 1.08333px;
}

.buttonSmall {
  height: 30px;
  padding: 0 22px;
  font-size: 11px;
  letter-spacing: 0.5px;
}

.buttonBlue {
  background-color: $blue;
  border: 1px solid $blue;
  color: $white;
}

.buttonBlue200 {
  background-color: $blue200;
  border: 1px solid $blue200;
}

.buttonRed {
  background-color: $red;
  border: 1px solid $red;
  color: $white;
  &:hover {
    background-color: $red;
    border: 1px solid $red;
  }
}

.buttonGray {
  background-color: #E8E8E8;
  border: 1px solid #E8E8E8;
  color: rgba($black, 0.7);
  &:hover {
    background-color: #E8E8E8;
    border: 1px solid #E8E8E8;
    color: rgba($black, 1);
  }
}

.disabled {
  pointer-events: none;
  border: 1px solid $gray200;
  background-color: $gray200;
  color: $white;
}

.loading {
  pointer-events: none;
  border: 1px solid $gray200;
  background-color: $gray200;
  color: $gray200;
}

.loader {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.smallLoader {
  width: 18px;
  height: 18px;
}
