@import "../../../styles/media";

.aside {
  width: 80px;
  height: 100vh;
  background-color: #1C2F38;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  @include mobile {
    display: none;
  }
  svg {
    cursor: pointer;
  }
  a {
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}
