@import "../../styles/variables";

.galleryWrapper {
  width: 100%;
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 0 50px;
  img {
    max-height: calc(100vh - 140px) !important;
  }
}

.nav {
  width: 50px;
  height: calc(100vh);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  svg {
    width: 25px;
    height: 25px;
    path {
      fill: $white;
    }
  }
}

.leftNav {
  left: 0;
  svg {
    transform: rotate(90deg);
  }
}

.rightNav {
  right: 0;
  svg {
    transform: rotate(-90deg);
  }
}

.close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
  z-index: 12;
  cursor: pointer;
}
