.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    opacity: 0;
    position: absolute;
  }
}

.loaded {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    opacity: 1;
    position: relative;
  }
}
