@import "../../styles/variables";

.wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 120px 40px 40px;
  z-index: 999;
  pointer-events: none;
  & > div {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
