@import "../../../styles/variables";

.popover {
  width: 440px;
  max-height: 100%;
  overflow-y: auto;
  padding: 25px;
  font-size: 14px;
  font-weight: 400;
  background-color: $white;
  box-shadow: $boxShadow;
  border-radius: 4px;
  position: absolute;
  z-index: 10;
  &::-webkit-scrollbar {
    display: none;
  }
}

.popoverTitle {
  color: $blue;
  margin-top: 24px;
  margin-bottom: 16px;
}

.date {
  color: $gray300;
  span {
    color: $blue;
  }
}

.tasks {
  margin-top: 25px;
}

.task {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid $borderColor;
  }
  & > div {
    flex-shrink: 0;
  }
}

.title {
  max-width: 273px;
  flex-grow: 1;
  a {
    opacity: 1;
    padding: 0;
  }
}

.completed {
  .title {
    text-decoration: line-through;
  }
}

.difficulty {
  margin: 0 10px;
  min-width: 30px;
  text-align: right;
}

.status {
  width: 14px;
  height: 14px;
}

.groupContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.groupTitle {
  display: flex;
  align-items: center;

  > a {
    color: #77CAA9;
  }
}

.groupTasks {
  width: 100%;
}