@import "../../styles/variables";

.toast {
  width: 440px;
  display: flex;
  align-items: center;
  min-height: 80px;
  background: $white;
  box-shadow: $boxShadow;
  border-radius: 4px;
  pointer-events: all;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  opacity: 0;
  transform: translateX(100%);
  transition: .4s cubic-bezier(0.65, 0.05, 0.36, 1);
  &.success {
    .icon {
      svg {
        width: 20px;
        height: 14px;
        path {
          fill: $green;
        }
      }
    }
    .title {
      color: $green;
    }
    .timeBar {
      background-color: $green;
    }
  }
  &.error {
    .icon {
      svg {
        transform: rotate(45deg);
        path {
          fill: $red100;
        }
      }
    }
    .title {
      color: $red100;
    }
    .timeBar {
      background-color: $red100;
    }
  }
  &.notification {
    .icon {
      svg {
        path {
          fill: $blue;
        }
      }
    }
    .title {
      color: $blue;
    }
    .timeBar {
      background-color: $blue;
    }
  }
  &.system {
    .title {
      color: $orange;
    }
    .timeBar {
      background-color: $orange;
    }
  }

  &.animation {
    opacity: 1;
    transform: translateX(0);
  }
}

.icon {
  min-width: 84px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  padding: 22px 32px 22px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  div {
    background: transparent;
  }
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $black;
  margin-top: 8px;
}

.timeBar {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  position: absolute;
  bottom: 0;
  animation: progressBar linear;
}

@keyframes progressBar {
  0% { width: 100%; }
  100% { width: 0; }
}
