.wrapper {
  width: 400px;
}

.title {
  font-size: 30px;
  font-weight: 600;
}

.form {
  margin-top: 24px;
  & > div {
    margin-top: 12px;
  }
  button {
    width: 100%;
    margin-top: 24px;
  }
}