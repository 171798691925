@import "../../../styles/variables";

.wrapper {
  width: 0;
  height: 3px;
  position: absolute;
  top: 99px;
  background-color: $blue;
  z-index: 105;
  animation: firstProgress 0.5s linear, secondProgress 5s linear;
  animation-delay: 0s, 0.5s;
}

@keyframes firstProgress {
    0% {
        width: 0;
    }
    100% {
        width: 90%;
    }
}

@keyframes secondProgress {
    0% {
        width: 90%;
    }
    100% {
        width: 99%;
    }
}