@import "../../../styles/variables";

.type {
  width: 10px;
  height: 10px;
  transform: translate(5px, 0);
  background-size: cover;
  border-radius: 100%;
  background-position: 50% 50%;
  margin: 0 auto;
  background-image: none;
}

.type0 {
  background: $blue400;
}

.type1 {
  background: $green200;
}

.type2 {
  background: $gray400;
}
