$rubik: 'Rubik', sans-serif;
$jetBrains: 'JetBrains Mono', monospace;
$black: #3D4044;
$black100: #41464B;
$white: #FBFAF9;
$white100: #E5F0EC;
$white200: #EDEDED;
$gray100: #D0D0D0;
$gray200: #C0C0C0;
$gray300: #959595;
$gray400: #B1B1B1;
$gray500: #B8B8B8;
$borderColor: #E9E9E9;
$borderColor100: #DDDDDD;
$blue: #7EA4E7;
$blue100: #3E65BE;
$blue200: #C6D2E5;
$blue300: #83C1DA;
$blue400: #E5E9F0;
$red: #D93352;
$red100: #C2697A;
$red200: #E77289;
$red300: #e0c9ce;
$marine: #253F4B;
$green: #77CAA9;
$green200: #5DBC96;
$green100: #E5EFEB;
$yellow: #FBE21A;
$purple: #C57EE7;
$purple100: #f5e3fd;
$orange: #E29964;
$orange100: #ffeee4;
$boxShadow: 0 2px 14px #DBE0EA;
$linkColor: #5C81C2;