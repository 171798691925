.skeleton {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #DDDBDD;
  z-index: 10;
  border-radius: 4px;
  position: relative;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.priority {
  width: 38px;
  height: 16px;
  background-color: transparent;
  margin: 0 auto;
  svg {
    width: inherit;
    height: inherit;
  }
}

.status, .type {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 auto;
}

.text {
  display: inline-block;
  height: 20px;
}