@import "../../styles/variables";

.tooltip {
  position: relative;
  pointer-events: all;
  &:hover {
    .text {
      opacity: 1;
      visibility: visible;
      transition-delay: .5s;
    }
  }
}

.text {
  opacity: 0;
  visibility: hidden;
  width: max-content;
  padding: 8px;
  background-color: #1C2F38;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $white;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease;
  pointer-events: none;
  transition-delay: 0s;
  z-index: 104;
  span {
    display: block;
    z-index: 2;
    position: relative;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-color: inherit;
    transform: rotate(45deg) translateX(-50%);
    position: absolute;
    top: 2px;
    left: calc(50% - 2px);
  }
}

.top {
  top: unset;
  bottom: calc(100% + 10px);
  &::before {
    top: unset;
    bottom: -9px;
  }
}

.left {
  top: 50%;
  left: unset;
  transform: translateY(-50%);
  right: calc(100% + 10px);
  &::before {
    left: unset;
    top: calc(50% - 2px);
    right: 2px;
    transform: rotate(45deg) translateX(0) translateY(-50%);
  }
}

.right {
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 10px);
  &::before {
    top: calc(50% - 2px);
    left: -9px;
    transform: rotate(45deg) translateX(0) translateY(-50%);
  }
}