@import "../../../../styles/variables";

.wrapper {
  width: 500px;
}

.block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 24px;
  &:first-child {
    margin-top: 0;
  }
  &Title {
    font-weight: 400;
  }
}

.interval {
  display: flex;
  .inputNumber {
    width: 50px;
    margin: 0 16px;
  }
}

.switches {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  margin-top: 16px;
  & > div {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 50%;
    color: $gray300;
    background-color: $borderColor;
    margin-right: 5px;
    cursor: pointer;
    &.active {
      background-color: $blue;
      color: $white;
    }
  }
}

.radioBlock {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  &:nth-child(2) {
    margin-top: 16px;
  }
  .datePicker {
    width: 125px;
    height: 32px;
    top: -8px;
    left: 86px;
    input {
      margin-top: 1px;
    }
  }
  .inputNumber {
    width: 70px;
    margin: 0 16px;
    transform: translateY(-7px);
  }
}

.button {
  margin-inline-start: auto;
}

.select {
  width: 100px;
}